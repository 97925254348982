import axios from 'axios';
import { authHeader } from './auth-header';
import { API_URL_CXC, API_URL_CATALOGO, ERR_MSG_CONEXION } from './constants';

class GrlService {

  getCargarCatalogosBase(AppCtx)
  {    
    return axios.post(API_URL_CATALOGO + '/catalogosbase',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getEstados(AppCtx, IdPais)
  {    
    return axios.post(API_URL_CATALOGO + '/estados',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${IdPais}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getCiudades(AppCtx, IdEstado)
  {    
    return axios.post(API_URL_CATALOGO + '/ciudades',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${IdEstado}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getMonedas(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/monedas', {
        AppCtx: `${AppCtx.codigo}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getMonedasXId(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/monedasxid', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getMonedaBase(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/monedabase', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getSucursales(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/sucursales', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getCtaHabienteRegiones(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/ctahabienteregiones', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
  
  getCtaHabienteTipos(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/ctaHabientetipos', {
        AppCtx: `${AppCtx.codigo}`        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getCategoriasCtaHabiente(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/ctahabientecategorias', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getCtaHabientes(AppCtx)
  {
      return axios.post(API_URL_CATALOGO + '/ctahabientes', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }


  registrarCtaCategoria(AppCtx, Categoria) {
    
    let data = JSON.stringify(Categoria);

    return axios.post(API_URL_CATALOGO + '/registrarctaHabientecategoria', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  CtaCategoriaEnUso(AppCtx, Categoria)
  {
    let id_cta_habiente_categoria = Categoria.id_cta_habiente_categoria;
    
    return axios.post(API_URL_CATALOGO + '/ctahabientecategoriaenuso', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_cta_habiente_categoria}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarCtaCategoria(AppCtx, Categoria)
  {
    let id_cta_habiente_categoria = Categoria.id_cta_habiente_categoria;
    
    return axios.post(API_URL_CATALOGO + '/removerctahabientecategoria', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_cta_habiente_categoria}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getCtaHabiente(AppCtx, Id)
  {
      return axios.post(API_URL_CATALOGO + '/ctahabiente', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Id}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getBuscaCtaHabiente(AppCtx, Tipo, Filtro)
  {
      return axios.post(API_URL_CATALOGO + '/buscactahabientes', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`,
        Tipo: `${Tipo}`,
        Filtro: `${Filtro}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getCtaHabienteDirecciones(AppCtx, Id)
  {
      return axios.post(API_URL_CATALOGO + '/ctahabientedirecciones', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Id}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
  
  async getCtaHabienteContactos(AppCtx, Id)
  {
    try {
      var data = axios.post(API_URL_CATALOGO + '/ctahabientecontactos', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Id}`      
      }, { headers: authHeader() });

      return data;
    } catch(err) {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
    } 
  }

  async getCtaHabienteCondicionesPago(AppCtx, Id)
  {
    try {
      var response = await axios.post(API_URL_CATALOGO + '/ctahabientecondicionespago', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Id}`      
      }, { headers: authHeader() });

      return response.data;
    } catch(err) {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
    } 
  }  
  getPreciosLista(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/precioslista', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getProductosLista(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productoslista', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GetPeriodoXFecha(AppCtx, fecha)
  {
    var anio = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    
    return axios.post(API_URL_CATALOGO + '/periodoxfecha', {
        AppCtx: `${AppCtx.codigo}`,
        Anio: `${anio}`,
        Mes: `${mes}`,
        Dia: `${dia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GetPlantillasMov(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/plantillamovs', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  registrarCtaHabiente(AppCtx, ctahabiente)
  {
    if(ctahabiente.activo){
      ctahabiente.activo = "S"
    } else {
      ctahabiente.activo = "N"
    }

    let data = JSON.stringify(ctahabiente);
    return axios.post(API_URL_CATALOGO + '/registrarctaHabiente', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `[${data}]`},
      { headers: authHeader() }).then(response => {
      return response.data;
    }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
    );    
  }

  eliminarCtaHabiente(AppCtx, ctahabiente)
  {
    let id_cta_habiente_info = ctahabiente.id_cta_habiente_info;
    
    return axios.post(API_URL_CATALOGO + '/eliminarctahabiente', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_cta_habiente_info}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }
  
  registrarCtaHabienteContacto(AppCtx, contacto)
  {
    let data = JSON.stringify(contacto);
    return axios.post(API_URL_CATALOGO + '/registrarctaHabientecontacto', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`},
      { headers: authHeader() }).then(response => {
    return response.data;
    }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
    );    
  }

  eliminarCtaHabienteContacto(AppCtx, contacto)
  {
    let id_contacto = contacto.id_contacto;
    
    return axios.post(API_URL_CATALOGO + '/eliminarctaHabientecontacto', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_contacto}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }

  registrarCtaHabienteDireccion(AppCtx, direccion)
  {
    let data = JSON.stringify(direccion);
    return axios.post(API_URL_CATALOGO + '/registrarctaHabientedireccion', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`},
      { headers: authHeader() }).then(response => {
    return response.data;
    }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
    );    
  }

  eliminarCtaHabienteDireccion(AppCtx, direccion)
  {
    let id_direccion = direccion.id_cta_habiente_direccion;
    
    return axios.post(API_URL_CATALOGO + '/eliminarctahabientedireccion', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_direccion}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }

  async registrarCtaHabienteCondicionPago(AppCtx, CondicionPago)
  {
    try {
      let data = JSON.stringify(CondicionPago);
      
      var response = await axios.post(API_URL_CATALOGO + '/registrarctaHabientecondicionpago', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`},
        { headers: authHeader() });
      
        return response.data;
    } catch (err) {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }    
  }

  async eliminarCtaHabienteCondicionPago(AppCtx, CondicionPago) {
    try {
      let id_cta_habiente_cond_pago = CondicionPago.id_cta_habiente_cond_pago;
   
      return axios.post(API_URL_CATALOGO + '/eliminarctahabientecondicionpago', 
        {
          AppCtx: `${AppCtx.codigo}`,
          Id: `${id_cta_habiente_cond_pago}`},
        { headers: authHeader() }).then(response => {
          return response.data;
        }
      )             
    } catch (err) {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  } 

  GetRptsXGrupo(AppCtx, id)
  {    
    return axios.post(API_URL_CATALOGO + '/getrptsxgrupo', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${id}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GetRptInfo(AppCtx, id)
  {    
    return axios.post(API_URL_CATALOGO + '/getrptinfo', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${id}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GetExportInfo(AppCtx, id)
  {    
    return axios.post(API_URL_CATALOGO + '/getexportinfo', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${id}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GetPerfilNotificacion(AppCtx, id)
  {    
    return axios.post(API_URL_CATALOGO + '/getnotificacionperfil', {
        AppCtx: `${AppCtx.codigo}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  SetPerfilNotificacion(AppCtx, Perfil)
  {    
    var data = JSON.stringify(Perfil)
    return axios.post(API_URL_CATALOGO + '/setnotificacionperfil', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
  
  registrarPlantillalNotificacion(AppCtx, Plantilla)
  {    
    var data = JSON.stringify(Plantilla)
    return axios.post(API_URL_CATALOGO + '/registrarplantillanotif', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  removerPlantillalNotificacion(AppCtx, Plantilla)
  {    
    var Id = Plantilla.id_notificacion_plantilla;
    return axios.post(API_URL_CATALOGO + '/removerplantillanotif', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Id}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GenerarRpt(AppCtx, reportData)
  {    
    let data = JSON.stringify(reportData);
    return axios.post(API_URL_CATALOGO + '/generarrpt', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`
      }, 
      { 
        headers: authHeader(),
        responseType: 'blob'
      }).then(response => {
        return response;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GenerarExport(AppCtx, reportData)
  {    
    let data = JSON.stringify(reportData);
    return axios.post(API_URL_CATALOGO + '/generarexport', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`
      },
      { 
        headers: authHeader()
      }      
      ).then(response => {
        return response;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getEstadoCuentaPDF(AppCtx, filtro, notif)
  {    
    let data = JSON.stringify(filtro);
    return axios.post(API_URL_CXC + '/getestadocuentapdf', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`,
        Notificar: notif
      }, 
      { 
        headers: authHeader(),
        responseType: 'blob'
      }).then(response => {
        return response;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

}

export default  new GrlService();
